//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {TheMask} from 'vue-the-mask';
import Loader from "@/components/main/Loader";
import config from '/app.config';

export default {
  name: "PinCode",
  components: {TheMask, Loader},
  data() {
    return {
      passwordPolicyEnable: config.variables && config.variables.passwordPolicyEnable,
    }
  },
  props: {
    id: String,
    error: Boolean,
    title: String,
    placeholder: String,
    value: String,
    setValue: Function,
    cleanErrors: Function,
    getPasswordError: Function,
    setPasswordError: Function,
    pending: Boolean,
    autocomplete: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    setInput(event) {
      if (event && event.key == " ") {
        event.preventDefault();
      }
    },
  }
}
